import React from 'react';
import { graphql } from 'gatsby';
import tw from 'twin.macro';
import Layout from '../components/Layout';
import Content from '../components/Content';
import SEO from '../components/Seo';

export default ({ data }) => (
  <Layout>
    <SEO title="Datenschutz" />
    <div css={tw`px-8 mx-auto sm:max-w-4xl lg:max-w-7xl`}>
      <h1
        className="underlined big"
        css={tw`mt-6 font-serif text-3xl sm:text-5xl`}
      >
        Datenschutz:
      </h1>
      <Content content={data.imprint._rawBody} />
    </div>
  </Layout>
);

export const query = graphql`
  {
    imprint: sanitySite(slug: { current: { eq: "datenschutz" } }) {
      title
      _rawBody
    }
  }
`;
